import React from 'react';

import site from '../data/site.json';

const DEFAULT_CLASSNAME: string = `aboutpage`;
const HEADER_CLASSNAME: string = `${DEFAULT_CLASSNAME}__header`;
const MAINCONTAINER_CLASSNAME: string = `${DEFAULT_CLASSNAME}__maincontainer`;
const TEXT_CLASSNAME: string = `${MAINCONTAINER_CLASSNAME}__text`;

const BULLETPOINTCONTAINER_CLASSNAME: string = `${MAINCONTAINER_CLASSNAME}__bulletpointcontainer`;
const BULLETPOINT_CLASSNAME: string = `${BULLETPOINTCONTAINER_CLASSNAME}__bulletpoint`;

const AboutPage = (): JSX.Element => {
  return (
    <div className={DEFAULT_CLASSNAME}>
        <div className={HEADER_CLASSNAME}>{site.aboutPage.mainheader}</div>

        <div className={MAINCONTAINER_CLASSNAME}>
          <div className={TEXT_CLASSNAME}>{site.aboutPage.textOne}</div>

          <div className={BULLETPOINTCONTAINER_CLASSNAME}>
            <li className={BULLETPOINT_CLASSNAME}>{site.aboutPage.bpOne}</li>
            <li className={BULLETPOINT_CLASSNAME}>{site.aboutPage.bpTwo}</li>
            <li className={BULLETPOINT_CLASSNAME}>{site.aboutPage.bpThree}</li>
            <li className={BULLETPOINT_CLASSNAME}>{site.aboutPage.bpFour}</li>
            <li className={BULLETPOINT_CLASSNAME}>{site.aboutPage.bpFive}</li>
            <li className={BULLETPOINT_CLASSNAME}>{site.aboutPage.bpSix}</li>
            <li className={BULLETPOINT_CLASSNAME}>{site.aboutPage.bpSeven}</li>
          </div>

          <div className={TEXT_CLASSNAME}>{site.aboutPage.textTwo}</div>
        </div>
    </div>
  );
}

export default AboutPage
